<template>
  <div class="product-wrap product-fire">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-fire"></div>
    <div class="white-wrap">
      <div class="txt-box align-ct no-indent pd-5">
        <p>智慧消防云平台，采用“感、传、知、用”等物联网技术手段，综合利用无线传感、云计算、大数据等技术，</p>
        <p>通过互联网、无线通信网、专网等通信网络，对消防设施、器材、人员等状态进行智能化感知、识别、定位与跟踪，</p>
        <p>实现实时、动态、互动、融合的消防信息采集、传递和处理，通过信息处理、数据挖掘和态势分析，</p>
        <p>为防火监督管理和灭火救援提供信息支撑，提高社会化消防监督与管理水平，增强消防灭火救援能力。</p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big  color-orange align-ct"><h4>平台优势</h4></div>
      <div class="ys-pic">
        <img src="../../assets/img/product/fire/pic_1.png" alt="平台优势">
      </div>
    </div>
    <div class="white-wrap">
      <div class="fz-big color-orange align-ct"><h4>平台架构</h4></div>
      <div class="jg-pic">
        <img src="../../assets/img/product/fire/pic_2.png" alt="平台架构">
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big color-orange align-ct"><h4>平台功能</h4></div>
      <div class="anchor-box">
        <div class="nav-btn fire-btn"><a href="#xftd">消防通道监测</a></div>
        <div class="nav-btn fire-btn"><a href="#xfsy">消防水源监测</a></div>
        <div class="nav-btn fire-btn"><a href="#xfss">消防设施监控</a></div>
        <div class="nav-btn fire-btn"><a href="#hzbj">火灾报警</a></div>
        <div class="nav-btn fire-btn"><a href="#ssxj">消防设施巡检</a></div>
        <div class="nav-btn fire-btn"><a href="#spld">视频联动分析</a></div>
        <div class="nav-btn fire-btn"><a href="#ygtc">智慧烟感探测</a></div>
        <div class="nav-btn fire-btn"><a href="#krqt">智慧可燃气体探测</a></div>
        <div class="nav-btn fire-btn"><a href="#dqhz">智慧电气火灾在线监控</a></div>
      </div>
      <div id="xftd"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic a-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>消防通道监测</h5></div>
              <div class="txt-box">
                <p>视频采集装置实时监测消防通道\重点部位状态，发现异常时将报警信息通过云平台实时传输至数据中心，系统通过智能研判，将报警信息发送给相关责任人，以便及时响应和处理。</p>
                <p>实时监控：通过视频采集装置，实时监控消防通道情况，发现异常，及时作出处理。</p>
                <p>图像智能识别：当消防通道堵塞或是人员密度较大时，会通过图像智能识别发出报警，使相关人员作出正确反应。</p>
                <p>报警受理：异常报警信息会同时推送至云平台，监控中心提供24小时报警受理服务，第一时间通过电话、短信或手机App通知相关责任人员响应和处理。</p>
                <p>数据分析：智慧消防应用平台运用大数据分析技术，实现消防分析图表的直观展示、消防火灾故障的本质挖掘和消防态势的准确研判。</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="xfsy"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>消防水源监测</h5></div>
              <div class="txt-box">
                <p>水压、水位感知：采集传输消防水系统中消火栓、喷淋末端等关键部位水压模拟量，及时发现水压异常和设备故障。通过水位计对消防水池、高位水箱水位远程实时监控。</p>
                <p>水压、水位异常报警：当水压、水位过低或过高时，该装置会自动推送实时水压、水位异常报警，以便业主单位和维保部门及时做出响应。</p>
                <p>报警受理：监控中心提供24小时报警受理服务，第一时间通知水务人员和联网单位人员及时 排查消防水源隐患。</p>
                <p>APP推送：监控中心提供智慧消防APP信息服务，第一时间推送故障信息，提醒水务人员和 联网单位人员及时处理消防水源故障。</p>
                <p>数据分析：智慧消防APP，可为消防部门、业主单位及维保单位提供统计查询报表等信息。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic b-pic" style="margin-top: 10%"></div>
          </el-col>
        </el-row>
      </div>
      <div id="xfss"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic c-pic "></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>消防设施监控</h5></div>
              <div class="txt-box">
                <p>设施在位、离位感知：通过离位标签与离位基站之间的通讯，及时发现消火栓、灭火器、水枪、水带等消防设施的在位状态信息。</p>
                <p>防火门状态异常报警：当常开式防火门闭合、或常闭式防火门打开时，防火门报警器会发出报警，及时发现防火门的异常状态。</p>
                <p>报警受理：异常报警信息会同时推送至云平台，郑州金特莱监控中心提供24小时报警受理服务，第一时间通过电话、短信或手机App通知业主单位、消防部门响应和处理。</p>
                <p>数据分析：智慧消防应用平台运用大数据分析技术，实现消防分析图表的直观展示、消防火灾故障的本质挖掘和消防态势的准确研判。</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="hzbj"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>火灾报警系统</h5></div>
              <div class="txt-box">
                <p>火警故障通知：通过前端火灾探测感知设备的监测和自动巡检，及时发现火灾和设施故障。</p>
                <p>火警、故障报警：当火警或故障报警发生时，火灾探测器自报警，消防主机二次报警，云平台APP推送三次报警。</p>
                <p>报警受理：监控中心提供24小时报警受理服务，第一时间通过电话、短信或手机APP通知 业主单位、消防部门、维保公司响应和处理。</p>
                <p>数据分析：云平台运用大数据分析技术，实现消防分析图表的直观展示、消防火灾故障的本质挖 掘和消防发展态势的准确研判。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic d-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="ssxj"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic e-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>消防设施巡检系统</h5></div>
              <div class="txt-box">
                采用物联网手段，为消防安全重点部位及消防设施建立身份标识，用手机扫描标签进行理性防火巡查工作，通过系统自动化提示的各种消防设施及重点部位的检查标准和方法，实现防火巡检和日常消防安全管理等工作的户籍化，标准化，痕迹化管理。能有效的促进值班人员直观检查，并且对巡查内容记录，对今后的数据统计提供了强有力的数据基础。云平台自动记录巡查人员检查痕迹并上传数据，系统存储，分析，管理数据，消防安全服务平台进行可视化数据分析，考核，通报，整改等管理。单位通过安全消防检查，对本单位消防安全制度，安全操作规程的落实和遵守情况进行检查，以监督规章制度，措施的贯彻落实。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="spld"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>视频联动分析系统</h5></div>
              <div class="txt-box">
                用户可以根据的视频内容分析功能，通过在不同摄像机的场景中预设不同的报警规则，一旦目标在场景中出现了违反预定义规则的行为，系统会自动发出报警，监控工作站自动弹出报警信息并发出警示音,用户可以通过点击报警信息，实现报警的场景重组并采取相关措施。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic f-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="ygtc"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic g-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>智慧烟感探测系统</h5></div>
              <div class="txt-box">
                智慧烟感又叫物联网独立式火灾探测报警器，是基于物联网技术的智慧感烟探测器，可以第一时间感知火灾信息并即时广域传输给业主、物业、安监、消防等部门，起到防范于未然及打早打小等效果，有效保证生命财产安全。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="krqt"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>智慧可燃气体探测系统</h5></div>
              <div class="txt-box">
                用于检测可燃气体泄漏，预防气体泄漏造成的危害。当探测器探测到有可燃气体泄漏并达到探测器设定的报警浓度时，探测器红色LED闪烁，并发出报警声音，同时，报警器的报警信号将通过无线底座采用NB-IoT网络无线上报给远端监控平台，以便监控人员及时处理。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic h-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="dqhz"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic i-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm color-orange align-lt"><h5>智慧电气火灾在线监控系统</h5></div>
              <div class="txt-box">
                智慧电气火灾在线监控系统可通过电力总线通讯网络，将本机下级终端传感器的故障报警等信息发送给电气火灾监控设备，完成监控、报警的综合处理。探测器具有传感器故障诊断、报警精度高、可靠性强（能有效防止误报、漏报）、小型化、多功能、简单实用、安装方便等特点。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-big color-orange align-lt" style="padding-top: 8%"><h4>定制化开发</h4></div>
              <div class="txt-box dzh-box">
                <p class="em">定制的不仅仅是软件和硬件 更是一种理念</p>
                <p>定制化开发是解决企业个性化难题唯一途径，而且是企业快速发展和提高竞争力的最好方法。</p>
                <el-button type="warning" plain class="zdh-btn" @click="handleShow">立即咨询</el-button>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="auto-img">
              <img src="../../assets/img/product/fire/pic_12.png" alt="定制化">
            </div>
          </el-col>
        </el-row>
        <div class="fz-sm color-orange align-ct" style="padding-top: 4%"><h5>定制化特点</h5></div>
        <div class="customized-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gps color-orange"></i></div>
            <p>针对性强</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-bianjie color-orange"></i></div>
            <p>操作便捷</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-price color-orange"></i></div>
            <p>性价比高</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-service color-orange"></i></div>
            <p>服务周到</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-upgrade color-orange"></i></div>
            <p>升级方便</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧农业-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧农业-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-fire {
  .banner-fire {
    background: url("../../assets/img/product/fire/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;

  }

  .a-pic {
    background-image: url("../../assets/img/product/fire/pic_3.png");
  }

  .b-pic {
    background-image: url("../../assets/img/product/fire/pic_4.png");
  }

  .c-pic {
    background-image: url("../../assets/img/product/fire/pic_5.png");
  }

  .d-pic {
    background-image: url("../../assets/img/product/fire/pic_6.png");
  }

  .e-pic {
    background-image: url("../../assets/img/product/fire/pic_7.png");
  }

  .f-pic {
    background-image: url("../../assets/img/product/fire/pic_8.png");
  }

  .g-pic {
    background-image: url("../../assets/img/product/fire/pic_9.png");
  }

  .h-pic {
    background-image: url("../../assets/img/product/fire/pic_10.png");
  }

  .i-pic {
    background-image: url("../../assets/img/product/fire/pic_11.png");
  }

  .j-pic {
    background-image: url("../../assets/img/product/fire/pic_12.png");
  }

}

</style>
